<template>
	<memory-form :memory="memory" :intent="intent"></memory-form>
</template>

<script>
import MemoryForm from './Form'
import Models from '../../models'
import {sync} from "vuex-pathify";

export default {
	name: "NewMemory",
	props: ['year', 'intent'],
	metaInfo: {
		title: 'New Memory',
	},
	components: {
		MemoryForm
	},
	computed:{
		user: sync('user/user')
	},
	beforeMount() {
		if (this.user.subscription_status === 'Lapsed') {
			this.$router.push({name: 'Account'});
		}
		const memoryYear = this.year ? new Date(`${parseInt(this.year)}/01/01 23:59:59`) : new Date();
		this.memory = Models.Memory(memoryYear.toISOString().substr(0, 10));
	},
	data: () => ({
		memory: null,
	}),
}
</script>