export default {
	User() {
		return {
			id: null,
			name: null,
			first_name: null,
			last_name: null,
			email: null,
			legacy_name: null,
			legacy_email: null,
			avatar: null,
			terms_agreed: false,
			marketing_opt_in: false,
			include_curated: false,
			subscription_status: '',
			days_until_deletion: null,
			trial_days_remaining: null,
			iap_check_required: false,
			public_memories_count: 0,
			linked_accounts: [
				{
					id: null,
					name: null,
					avatar: null
				}
			]
		}
	},
	Memory(date) {
		return {
			id: null,
			title: '',
			description: '',
			topics_attributes: [],
			topics: [],
			date: date,
			is_shared: false,
			featured_image_attributes: '',
			featured_image: {
				url: null,
			},
			owner: {
				id: null,
				name: null,
			},
			user_shared_memories_attributes: [],
			attachments_attributes:[],
			is_owner: false,
			share_token: null,
			urls: [],
			pin_to_year: false,
		}
	},
	Topic() {
		return {
			id: null,
			name: '',
			slug: ''
		}
	},
	Notification() {
		return {
			id: null,
			requestor: {
				id: 4,
				name: '',
				email: ''
			},
			text: '',
			subtext: '',
			date: null,
			type: 'share',
		}
	},
	Product() {
		return {
			id: null,
			name: null,
			price: null,
			frequency: null,
			description: null,
		}
	},
}